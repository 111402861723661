import React from 'react';
import { MdOutlineFindInPage, MdAdminPanelSettings, MdOutlineFactCheck } from 'react-icons/md';
import PageCards from '../../components/common/PageCards';
import { TbDatabaseCog } from 'react-icons/tb';

const AdminHome = () =>  {
    /**
    * Creating an array of pages
    * card data with icon and url
    */
    const cards= [
        {
            icon: <MdOutlineFindInPage size={'100px'}/>,
            url: 'ViewLogs',
            title: 'View Logs',
            externalLink: false
        },
        {
            icon: <MdAdminPanelSettings size={'100px'}/>,
            url: 'DBAdmin',
            title: 'Database Admin',
            externalLink: false
        },
        {
            icon: <TbDatabaseCog size={'100px'}/>,
            url: 'JobAdmin',
            title: 'Job Admin',
            externalLink: false
        },
        {
            icon: <MdOutlineFactCheck size={'100px'}/>,
            url: 'AuditLog',
            title: 'Audit Log',
            externalLink: false
        },
    ];    
    // return 
    return (
        <div>
            <PageCards pages={cards} />
        </div>
    );
};

  
export default AdminHome;
  
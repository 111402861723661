import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { IconButton } from '@mui/material';
import { DataTable } from '@jauj/reactpkg-tsis-datatable';


const AuditLogSearchTable = ({ recordData, selectedTable }) => { 

    const colConfig = [
        {
            header: 'Action',
            textAlign: 'center',
            disableFilter: true,
            minWidth: 70,
            cell: info => (
                <IconButton 
                    component={Link} 
                    to={`/admin/AuditLog/details?recordid=${info.row.original[selectedTable.primary_key_columns[0]]}&tableid=${selectedTable.table_id}`}
                    target='_blank'
                    disabled={selectedTable.primary_key_columns.length > 1}
                >
                    { selectedTable.primary_key_columns.length > 1 ? <FaEyeSlash/> : <FaEye/> }
                </IconButton>
            )
        },
        {
            header: 'Primary Key', 
            textAlign: 'left',
            dataType: 'string',
            filterFn: 'stringFilter',
            disableFilter: true, 
            cell: info => (
                <div className='audit-search-item-pkey-section'>
                    {
                        selectedTable.primary_key_columns.map(col => 
                            (
                                <div className='audit-search-item-pkey' key={`pkey-${col}`}>
                                    <p className='bold'>{col}:</p>
                                    <p>{info.row.original[col]}</p>
                                </div>
                            )
                        )
                    }
                </div>
            )
        },
        {
            header: `Display Value (${selectedTable?.table_display_column ?? 'N/A'})`, 
            textAlign: 'left',
            dataType: 'string',
            filterFn: 'stringFilter',
            cell: info => (
                <div className='audit-search-item-dv-section'>
                    {
                        !selectedTable?.table_display_column 
                            ? <p>N/A</p>
                            : <p>{info.row.original[selectedTable.table_display_column]}</p>
                    }
                </div>
            )
        }
    ];

    return(
        <div data-testid='audit-log-search-table'>
            { (selectedTable && recordData.length > 0)
                ? <DataTable
                    {...{
                        tableData: recordData,
                        columnConfig: colConfig,
                        disableDownload: true,
                        disableFilter: false,
                        themeColor: '#5856d6'
                    }}
                />
                :   <p style={{fontSize:'1.5em', textAlign:'center', marginTop:'4rem'}}>There is no data for the current selection.</p>
            }
        </div>
    );

};

AuditLogSearchTable.propTypes = { 
    recordData: PropTypes.array.isRequired,
    selectedTable: PropTypes.object
};

export default AuditLogSearchTable;
import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Button, TextField } from '@mui/material';
import Select from 'react-select';

import { getRequest } from '../../services/axiosClient';
import { getBaseUrl } from '../../util/getBaseUrl';
import { getDetailErrorMsg } from '../../util/functions';
import LoadingAnimation from '../../components/common/loadingAnimation/LoadingAnimation';
import AuditLogSearchTable from './AuditLogSearchTable';

import './AuditLog.css';

const tableHierarchyUrl = `${getBaseUrl()}/api/datasrcs/1/folder/tspmcfg/customQuery/table_hierarchy`;

const AuditLogSearch = () => { 
    const [recordData, setRecordData] = useState([]);

    const [hierarchyData, setHierarchyData] = useState([]);

    const [selectedDataSrc, setSelectedDataSrc] = useState(null);
    const [selectedSchema, setSelectedSchema] = useState(null);
    const [selectedTable, setSelectedTable] = useState(null);

    const [searchInput, setSearchInput] = useState('');
    const [loading, setLoading] = useState(true);
    const [tableLoading, setTableLoading] = useState(false);

    useEffect(() => {  
        const loadData = async () => { 
            setLoading(true);
            try { 
                const response = await getRequest(tableHierarchyUrl);
                setHierarchyData(response.data.data);
            } catch(e) {
                toast.error(`Error loading data: ${getDetailErrorMsg(e)}`);
            } finally { 
                setLoading(false);
            }
        };
        loadData();
    }, []);

    const handleSearch = async () => {
        const datasrc_id = selectedDataSrc.datasrc_id;
        const schema_name = selectedSchema.schema_name;
        const table_name = selectedTable.table_name;
        const searchFields = selectedTable.primary_key_columns.concat(selectedTable.table_display_column || []).join(',');
        try {
            setTableLoading(true);
            const recordSearchUrl = `${getBaseUrl()}/api/datasrcs/${datasrc_id}/schemas/${schema_name}/tables/${table_name}/records?search-fields=${searchFields}&search-term=${searchInput}&fields-all=${searchFields}&record-limit=1000`;
            const response = await getRequest(recordSearchUrl);
            setRecordData(response.data.data);
        } catch (e) { 
            toast.error(`Error loading data: ${getDetailErrorMsg(e)}`);
        } finally { 
            setTableLoading(false);
        }
    };

    if(loading) return <LoadingAnimation/>;
    return(
        <div className='audit-container'>
            <ToastContainer theme='light' position='bottom-right' />
            <div className='audit-input-container'>
                <div className='audit-input-top-row'>
                    <div className='audit-select-div'>
                        <p>Data Source</p>
                        <Select
                            options={hierarchyData}
                            value={selectedDataSrc ?? null}
                            onChange={opt => {setSelectedDataSrc(opt); setSelectedSchema(null); setSelectedTable(null);}}
                            getOptionLabel={opt => opt.datasrc_name}
                            getOptionValue={opt => opt.datasrc_id}
                            aria-label='select-datasrc'
                        />
                    </div>
                    <div className='audit-select-div'>
                        <p>Schema</p>
                        <Select
                            options={selectedDataSrc?.schemas ?? null}
                            value={selectedSchema ?? null}
                            onChange={opt => {setSelectedSchema(opt); setSelectedTable(null);}}
                            getOptionLabel={opt => opt.schema_name}
                            getOptionValue={opt => opt.schema_id}
                            aria-label='select-schema'
                            isDisabled={!selectedDataSrc}
                        />
                    </div>
                    <div className='audit-select-div'>
                        <p>Table</p>
                        <Select
                            options={selectedSchema?.tables ?? null}
                            value={selectedTable ?? null}
                            onChange={opt => {setSelectedTable(opt); setRecordData([]);}}
                            getOptionLabel={opt => opt.table_name}
                            getOptionValue={opt => opt.table_id}
                            aria-label='select-table'
                            isDisabled={!selectedSchema}
                        />
                    </div>
                </div>
                <div className='audit-input-second-row'>
                    <TextField
                        data-testid='audit-search-input'
                        hiddenLabel
                        size='small'
                        variant='outlined'
                        InputLabelProps={{shrink: false}}
                        sx={{backgroundColor: 'white', minWidth: '240px'}}
                        value={searchInput}
                        onChange={e => {setSearchInput(e.target.value);}}
                        disabled={!selectedTable}
                    />
                    <Button 
                        data-testid='audit-search-btn'
                        variant='contained'
                        onClick={handleSearch}
                        disabled={!selectedTable}
                    >
                        Search
                    </Button>
                    <div className='audit-search-cols'>
                        <p className='bold'>Search Columns:</p>
                        <p>
                            {
                                selectedTable 
                                    ? [...selectedTable.primary_key_columns, selectedTable.table_display_column].filter(i => i !== null && i !== undefined).join(', ')
                                    : 'N/A'
                            }                               
                        </p>
                    </div>
                </div>
            </div>
            { tableLoading
                ? <LoadingAnimation/>
                : <AuditLogSearchTable recordData={recordData} selectedTable={selectedTable}/>
            }
        </div>
    );
};

export default AuditLogSearch;
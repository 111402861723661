import React from 'react';
import PropTypes from 'prop-types';
import { DataTable } from '@jauj/reactpkg-tsis-datatable';
import './AuditLog.css';

const AuditLogDetailsTable = ({ auditData }) => {

    auditData.forEach(item => item.change_user = item.change_user ? item.change_user : 'N/A');
    const colConfig = [
        {
            accessorKey: 'change_user', 
            header: 'User',
            textAlign: 'center',
            dataType: 'string',
            filterFn: 'stringFilter',
            disableFilter: false,
        },
        {
            accessorKey: 'change_date', 
            header: 'Timestamp',
            textAlign: 'center',
            dataType: 'date',
            filterFn: 'dateFilter',
            disableFilter: false,
        },
        {
            accessorKey: 'field_name', 
            header: 'Field',
            textAlign: 'center',
            dataType: 'string',
            filterFn: 'stringFilter',
            disableFilter: false,
        },
        {
            accessorKey: 'value', 
            header: 'Value',
            textAlign: 'center',
            dataType: 'string',
            filterFn: 'stringFilter',
            disableFilter: false,
        }
    ];

    return(
        <div className='audit-log-table'>
            {
                auditData.length > 0 
                    ? <DataTable
                        {...{
                            tableData: auditData,
                            columnConfig: colConfig,
                            disableDownload: true,
                            disableFilter: false,
                            themeColor: '#5856d6',
                            initialRowsPerPage: 20
                        }}
                    />
                    : <p style={{fontSize:'1.5em', textAlign:'center', marginTop:'4rem'}}>There is no data for the current selection.</p>
            }
        </div>
    );    
};

AuditLogDetailsTable.propTypes = { 
    auditData: PropTypes.array.isRequired
};

export default AuditLogDetailsTable;
import React, { useEffect, useState } from 'react';
import './app.css';
import AdminHome from './pages/adminHome/adminHome';
import { Route, Routes, useLocation } from 'react-router-dom';
import AuthContext from './store/auth-context';
import Loading from './components/common/loading';
import AuthRedirectError from './components/common/AuthRedirectError';
import DataMaintenance from './pages/data_maintenance/dataMaintenance';
import HomePage from './pages/homepage/homepage';
import Error404 from './pages/Error404.jsx';
import Header from './components/header/header';
import DataExploration from './pages/explore/DataExploration';
import Dashboards from './pages/dashboards/dashboards';
import useAuthentication from './components/useAuthentication';
import logPageView from './components/logPageView';
import Logger from './pages/adminHome/Logger';
import DBAdmin from './pages/adminHome/DBAdmin';
import JobAdmin from './pages/adminHome/JobAdmin';
import AuthReload from './components/common/AuthReload';
import DataMart from './pages/explore/DataMart';
import Surveys from './pages/Surveys/Surveys.jsx';
import Admin from './pages/adminHome/Admin.jsx';
import SurveyAdminForm from './pages/Surveys/SurveyAdmin/SurveyAdminForm.jsx';
import SurveyAdmin from './pages/Surveys/SurveyAdmin/SurveyAdmin.jsx';
import SurveyResponseViewer from './pages/Surveys/SurveyResponseViewer';
import AuditLogSearch from './pages/auditLog/AuditLogSearch';
import AuditLogDetails from './pages/auditLog/AuditLogDetails';
import AuditLog from './pages/auditLog/AuditLog';

function App() {
    //master control for page header title. 
    const [title, setTitle] = useState('TS Insights & Solutions Homepage');
    const [pageview, setPageview] = useState('');
    //states for initial useAuthenticationHook on load of page.
    const { authError, isAuthenticated, isAdmin, authStatus, userInfo, statusMsg } = useAuthentication();

    // getting params
    // use location to capture changes to the pages that the user is viewing
    const location = useLocation();
    useEffect(() => {
        setPageview(location);
    }, [location.key]);

    // check
    // if user is authenticated
    // and save user data
    // and link by using
    // logpageview function
    useEffect(() => {
        if (isAuthenticated) {
            logPageView(userInfo.email, pageview);
        }
    }, [pageview, isAuthenticated]);

    //routes include path for user to access form edit from link
    function renderAppContent() {
        const errorCode = 403;
        if (isAuthenticated) {
            return ( 
                <>
                    <Header heading={title} userInfo={userInfo} isAdmin={isAdmin} />
                    <AuthReload />
                    <div>
                        <AuthContext.Provider value={userInfo.email} isAdmin={isAdmin}>
                            <Routes>
                                <Route path="/" element={<HomePage setTitle={setTitle} />} />
                                <Route path='home' element={<HomePage setTitle={setTitle} />} />
                                {isAdmin && <Route path='admin' element={<Admin setTitle={setTitle} />} >
                                    <Route index  element={<AdminHome />} />
                                    <Route path='ViewLogs' element={<Logger />} />
                                    <Route path='DBAdmin' element={<DBAdmin />} />
                                    <Route path='JobAdmin' element={<JobAdmin />} />
                                    <Route path='AuditLog' element={<AuditLog setTitle={setTitle}/>}>
                                        <Route index element={<AuditLogSearch/>} /> 
                                        <Route path='details' element={<AuditLogDetails/>} />
                                    </Route>
                                </Route>}
                                <Route path='survey' element={<Surveys userWwid={userInfo.wwid} isAdmin={isAdmin}/>}/>
                                <Route path='survey-admin-form' element={<SurveyAdmin wwid={userInfo.wwid} isAdmin={isAdmin}/>}>
                                    <Route path=':id' element={<SurveyAdminForm wwid={userInfo.wwid} isAdmin={isAdmin}/>}/>
                                </Route>
                                <Route path='survey-responses/:id?' element={<SurveyResponseViewer userWwid={userInfo.wwid} isAdmin={isAdmin}/>}/>
                                <Route path='survey/:id' element={<Surveys userWwid={userInfo.wwid} isAdmin={isAdmin}/>} />
                                <Route path='dashboards/*' element={<Dashboards setTitle={setTitle} userId={userInfo.wwid} />} />
                                <Route path='explore'>
                                    <Route index={true} element={<DataExploration setTitle={setTitle} userId={userInfo.wwid} userName={userInfo.userName} />} />
                                    <Route index={false} path=':tableId' element={<DataMart isAdminGroup={userInfo.groups} />} />
                                </Route>
                                
                                {/* <Route path='audit-log-details' element={<AuditLogDetails/>} /> */}
                                <Route path='data_maintenance' element={<DataMaintenance setTitle={setTitle} />} >
                                    <Route path=':tableId' element={<DataMaintenance setTitle={setTitle} />} />
                                    <Route path=':tableId/filtered/:query' element={<DataMaintenance setTitle={setTitle} />} />
                                    <Route path=':tableId/form/:action/:recordId/*' element={<DataMaintenance setTitle={setTitle} />} />
                                    <Route path=':tableId/form/:action/:recordId/filtered/:query' element={<DataMaintenance setTitle={setTitle} />} />
                                </Route>
                                <Route path="*" element={<Error404 />} />
                            </Routes>
                        </AuthContext.Provider>
                    </div>
                </>
            );
        } else if (authStatus === errorCode) {
            return <div></div>;
        } else {
            return <Loading isLoading={true} />;
        }
    }

    return (
        <div className="app">
            {authError && <AuthRedirectError error={authStatus} message={statusMsg} />}
            {renderAppContent()}
        </div>
    );
}

export default App;